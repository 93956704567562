import React from 'react';
import Projects from '../components/Projects';
import Layout from '../components/layout';
import SEO from '../components/seo';

const ProjectsPage = () => (
  <Layout>
    <SEO title="Projects" />
    <Projects></Projects>
  </Layout>
);

export default ProjectsPage;
